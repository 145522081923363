export const TestDataCompany = [
  {
    symbol: "AAPL",
    price: 145.85,
    beta: 1.201965,
    volAvg: 79766736,
    mktCap: 2410929717248,
    lastDiv: 0.85,
    range: "105.0-157.26",
    changes: 2.4200134,
    companyName: "Apple Inc.",
    currency: "USD",
    cik: "0000320193",
    isin: "US0378331005",
    cusip: "037833100",
    exchange: "Nasdaq Global Select",
    exchangeShortName: "NASDAQ",
    industry: "Consumer Electronics",
    website: "http://www.apple.com",
    description:
      "Apple Inc. designs, manufactures, and markets smartphones, personal computers, tablets, wearables, and accessories worldwide. It also sells various related services. The company offers iPhone, a line of smartphones; Mac, a line of personal computers; iPad, a line of multi-purpose tablets; and wearables, home, and accessories comprising AirPods, Apple TV, Apple Watch, Beats products, HomePod, iPod touch, and other Apple-branded and third-party accessories. It also provides AppleCare support services; cloud services store services; and operates various platforms, including the App Store, that allow customers to discover and download applications and digital content, such as books, music, video, games, and podcasts. In addition, the company offers various services, such as Apple Arcade, a game subscription service; Apple Music, which offers users a curated listening experience with on-demand radio stations; Apple News+, a subscription news and magazine service; Apple TV+, which offers exclusive original content; Apple Card, a co-branded credit card; and Apple Pay, a cashless payment service, as well as licenses its intellectual property. The company serves consumers, and small and mid-sized businesses; and the education, enterprise, and government markets. It sells and delivers third-party applications for its products through the App Store. The company also sells its products through its retail and online stores, and direct sales force; and third-party cellular network carriers, wholesalers, retailers, and resellers. Apple Inc. was founded in 1977 and is headquartered in Cupertino, California.",
    ceo: "Mr. Timothy Cook",
    sector: "Technology",
    country: "US",
    fullTimeEmployees: "147000",
    phone: "14089961010",
    address: "1 Apple Park Way",
    city: "Cupertino",
    state: "CALIFORNIA",
    zip: "95014",
    dcfDiff: 89.92,
    dcf: 148.019,
    image: "https://financialmodelingprep.com/image-stock/AAPL.png",
    ipoDate: "1980-12-12",
    defaultImage: false,
    isEtf: false,
    isActivelyTrading: true,
    isAdr: false,
    isFund: false,
  },
];

export const testIncomeStatementData = [
  {
    date: "2022-09-24",
    symbol: "AAPL",
    reportedCurrency: "USD",
    cik: "0000320193",
    fillingDate: "2022-10-28",
    acceptedDate: "2022-10-27 18:01:14",
    calendarYear: "2022",
    period: "FY",
    revenue: 394328000000,
    costOfRevenue: 223546000000,
    grossProfit: 170782000000,
    grossProfitRatio: 0.4330963056,
    researchAndDevelopmentExpenses: 26251000000,
    generalAndAdministrativeExpenses: 0,
    sellingAndMarketingExpenses: 0,
    sellingGeneralAndAdministrativeExpenses: 25094000000,
    otherExpenses: -334000000,
    operatingExpenses: 51345000000,
    costAndExpenses: 274891000000,
    interestIncome: 2825000000,
    interestExpense: 2931000000,
    depreciationAndAmortization: 11104000000,
    ebitda: 130541000000,
    ebitdaratio: 0.3310467428,
    operatingIncome: 119437000000,
    operatingIncomeRatio: 0.302887444,
    totalOtherIncomeExpensesNet: -334000000,
    incomeBeforeTax: 119103000000,
    incomeBeforeTaxRatio: 0.3020404333,
    incomeTaxExpense: 19300000000,
    netIncome: 99803000000,
    netIncomeRatio: 0.2530964071,
    eps: 6.15,
    epsdiluted: 6.11,
    weightedAverageShsOut: 16215963000,
    weightedAverageShsOutDil: 16325819000,
    link: "https://www.sec.gov/Archives/edgar/data/320193/000032019322000108/0000320193-22-000108-index.htm",
    finalLink:
      "https://www.sec.gov/Archives/edgar/data/320193/000032019322000108/aapl-20220924.htm",
  },
  {
    date: "2021-09-25",
    symbol: "AAPL",
    reportedCurrency: "USD",
    cik: "0000320193",
    fillingDate: "2021-10-29",
    acceptedDate: "2021-10-28 18:04:28",
    calendarYear: "2021",
    period: "FY",
    revenue: 365817000000,
    costOfRevenue: 212981000000,
    grossProfit: 152836000000,
    grossProfitRatio: 0.4177935963,
    researchAndDevelopmentExpenses: 21914000000,
    generalAndAdministrativeExpenses: 0,
    sellingAndMarketingExpenses: 0,
    sellingGeneralAndAdministrativeExpenses: 21973000000,
    otherExpenses: 258000000,
    operatingExpenses: 43887000000,
    costAndExpenses: 256868000000,
    interestIncome: 2843000000,
    interestExpense: 2645000000,
    depreciationAndAmortization: 11284000000,
    ebitda: 120233000000,
    ebitdaratio: 0.3286697994,
    operatingIncome: 108949000000,
    operatingIncomeRatio: 0.2978237753,
    totalOtherIncomeExpensesNet: 258000000,
    incomeBeforeTax: 109207000000,
    incomeBeforeTaxRatio: 0.2985290459,
    incomeTaxExpense: 14527000000,
    netIncome: 94680000000,
    netIncomeRatio: 0.2588179336,
    eps: 5.67,
    epsdiluted: 5.61,
    weightedAverageShsOut: 16701272000,
    weightedAverageShsOutDil: 16864919000,
    link: "https://www.sec.gov/Archives/edgar/data/320193/000032019321000105/0000320193-21-000105-index.htm",
    finalLink:
      "https://www.sec.gov/Archives/edgar/data/320193/000032019321000105/aapl-20210925.htm",
  },
  {
    date: "2020-09-26",
    symbol: "AAPL",
    reportedCurrency: "USD",
    cik: "0000320193",
    fillingDate: "2020-10-30",
    acceptedDate: "2020-10-29 18:06:25",
    calendarYear: "2020",
    period: "FY",
    revenue: 274515000000,
    costOfRevenue: 169559000000,
    grossProfit: 104956000000,
    grossProfitRatio: 0.3823324773,
    researchAndDevelopmentExpenses: 18752000000,
    generalAndAdministrativeExpenses: 0,
    sellingAndMarketingExpenses: 0,
    sellingGeneralAndAdministrativeExpenses: 19916000000,
    otherExpenses: 803000000,
    operatingExpenses: 38668000000,
    costAndExpenses: 208227000000,
    interestIncome: 3763000000,
    interestExpense: 2873000000,
    depreciationAndAmortization: 11056000000,
    ebitda: 77344000000,
    ebitdaratio: 0.2817478098,
    operatingIncome: 66288000000,
    operatingIncomeRatio: 0.2414731435,
    totalOtherIncomeExpensesNet: 803000000,
    incomeBeforeTax: 67091000000,
    incomeBeforeTaxRatio: 0.2443983025,
    incomeTaxExpense: 9680000000,
    netIncome: 57411000000,
    netIncomeRatio: 0.2091361128,
    eps: 3.31,
    epsdiluted: 3.28,
    weightedAverageShsOut: 17352119000,
    weightedAverageShsOutDil: 17528214000,
    link: "https://www.sec.gov/Archives/edgar/data/320193/000032019320000096/0000320193-20-000096-index.htm",
    finalLink:
      "https://www.sec.gov/Archives/edgar/data/320193/000032019320000096/aapl-20200926.htm",
  },
  {
    date: "2019-09-28",
    symbol: "AAPL",
    reportedCurrency: "USD",
    cik: "0000320193",
    fillingDate: "2019-10-31",
    acceptedDate: "2019-10-30 18:12:36",
    calendarYear: "2019",
    period: "FY",
    revenue: 260174000000,
    costOfRevenue: 161782000000,
    grossProfit: 98392000000,
    grossProfitRatio: 0.3781776811,
    researchAndDevelopmentExpenses: 16217000000,
    generalAndAdministrativeExpenses: 0,
    sellingAndMarketingExpenses: 0,
    sellingGeneralAndAdministrativeExpenses: 18245000000,
    otherExpenses: 1807000000,
    operatingExpenses: 34462000000,
    costAndExpenses: 196244000000,
    interestIncome: 4961000000,
    interestExpense: 3576000000,
    depreciationAndAmortization: 12547000000,
    ebitda: 76477000000,
    ebitdaratio: 0.2939455903,
    operatingIncome: 63930000000,
    operatingIncomeRatio: 0.2457201719,
    totalOtherIncomeExpensesNet: 1807000000,
    incomeBeforeTax: 65737000000,
    incomeBeforeTaxRatio: 0.2526655238,
    incomeTaxExpense: 10481000000,
    netIncome: 55256000000,
    netIncomeRatio: 0.2123809451,
    eps: 2.99,
    epsdiluted: 2.97,
    weightedAverageShsOut: 18471336000,
    weightedAverageShsOutDil: 18595652000,
    link: "https://www.sec.gov/Archives/edgar/data/320193/000032019319000119/0000320193-19-000119-index.htm",
    finalLink:
      "https://www.sec.gov/Archives/edgar/data/320193/000032019319000119/a10-k20199282019.htm",
  },
  {
    date: "2018-09-29",
    symbol: "AAPL",
    reportedCurrency: "USD",
    cik: "0000320193",
    fillingDate: "2018-11-05",
    acceptedDate: "2018-11-05 08:01:40",
    calendarYear: "2018",
    period: "FY",
    revenue: 265595000000,
    costOfRevenue: 163756000000,
    grossProfit: 101839000000,
    grossProfitRatio: 0.3834371882,
    researchAndDevelopmentExpenses: 14236000000,
    generalAndAdministrativeExpenses: 0,
    sellingAndMarketingExpenses: 0,
    sellingGeneralAndAdministrativeExpenses: 16705000000,
    otherExpenses: 2005000000,
    operatingExpenses: 30941000000,
    costAndExpenses: 194697000000,
    interestIncome: 5686000000,
    interestExpense: 3240000000,
    depreciationAndAmortization: 10903000000,
    ebitda: 81801000000,
    ebitdaratio: 0.3079914908,
    operatingIncome: 70898000000,
    operatingIncomeRatio: 0.2669402662,
    totalOtherIncomeExpensesNet: 2005000000,
    incomeBeforeTax: 72903000000,
    incomeBeforeTaxRatio: 0.2744893541,
    incomeTaxExpense: 13372000000,
    netIncome: 59531000000,
    netIncomeRatio: 0.2241420207,
    eps: 3,
    epsdiluted: 2.98,
    weightedAverageShsOut: 19821508000,
    weightedAverageShsOutDil: 20000436000,
    link: "https://www.sec.gov/Archives/edgar/data/320193/000032019318000145/0000320193-18-000145-index.htm",
    finalLink:
      "https://www.sec.gov/Archives/edgar/data/320193/000032019318000145/a10-k20189292018.htm",
  },
  {
    date: "2017-09-30",
    symbol: "AAPL",
    reportedCurrency: "USD",
    cik: "0000320193",
    fillingDate: "2017-11-03",
    acceptedDate: "2017-11-03 08:01:37",
    calendarYear: "2017",
    period: "FY",
    revenue: 229234000000,
    costOfRevenue: 141048000000,
    grossProfit: 88186000000,
    grossProfitRatio: 0.3846986049,
    researchAndDevelopmentExpenses: 11581000000,
    generalAndAdministrativeExpenses: 0,
    sellingAndMarketingExpenses: 0,
    sellingGeneralAndAdministrativeExpenses: 15261000000,
    otherExpenses: 2745000000,
    operatingExpenses: 26842000000,
    costAndExpenses: 167890000000,
    interestIncome: 5201000000,
    interestExpense: 2323000000,
    depreciationAndAmortization: 10157000000,
    ebitda: 71501000000,
    ebitdaratio: 0.311912718,
    operatingIncome: 61344000000,
    operatingIncomeRatio: 0.2676042821,
    totalOtherIncomeExpensesNet: 2745000000,
    incomeBeforeTax: 64089000000,
    incomeBeforeTaxRatio: 0.2795789455,
    incomeTaxExpense: 15738000000,
    netIncome: 48351000000,
    netIncomeRatio: 0.2109242085,
    eps: 2.32,
    epsdiluted: 2.3,
    weightedAverageShsOut: 20868968000,
    weightedAverageShsOutDil: 21006768000,
    link: "https://www.sec.gov/Archives/edgar/data/320193/000032019317000070/0000320193-17-000070-index.htm",
    finalLink:
      "https://www.sec.gov/Archives/edgar/data/320193/000032019317000070/a10-k20179302017.htm",
  },
  {
    date: "2016-09-24",
    symbol: "AAPL",
    reportedCurrency: "USD",
    cik: "0000320193",
    fillingDate: "2016-10-26",
    acceptedDate: "2016-10-26 16:42:16",
    calendarYear: "2016",
    period: "FY",
    revenue: 215639000000,
    costOfRevenue: 131376000000,
    grossProfit: 84263000000,
    grossProfitRatio: 0.3907595565,
    researchAndDevelopmentExpenses: 10045000000,
    generalAndAdministrativeExpenses: 0,
    sellingAndMarketingExpenses: 0,
    sellingGeneralAndAdministrativeExpenses: 14194000000,
    otherExpenses: 1348000000,
    operatingExpenses: 24239000000,
    costAndExpenses: 155615000000,
    interestIncome: 3999000000,
    interestExpense: 1456000000,
    depreciationAndAmortization: 10505000000,
    ebitda: 70529000000,
    ebitdaratio: 0.3270697787,
    operatingIncome: 62567000000,
    operatingIncomeRatio: 0.2901469586,
    totalOtherIncomeExpensesNet: 1348000000,
    incomeBeforeTax: 61372000000,
    incomeBeforeTaxRatio: 0.2846052894,
    incomeTaxExpense: 15685000000,
    netIncome: 45687000000,
    netIncomeRatio: 0.2118679831,
    eps: 2.09,
    epsdiluted: 2.08,
    weightedAverageShsOut: 21883280000,
    weightedAverageShsOutDil: 22001124000,
    link: "https://www.sec.gov/Archives/edgar/data/320193/000162828016020309/0001628280-16-020309-index.htm",
    finalLink:
      "https://www.sec.gov/Archives/edgar/data/320193/000162828016020309/a201610-k9242016.htm",
  },
  {
    date: "2015-09-26",
    symbol: "AAPL",
    reportedCurrency: "USD",
    cik: "0000320193",
    fillingDate: "2015-10-28",
    acceptedDate: "2015-10-28 16:31:09",
    calendarYear: "2015",
    period: "FY",
    revenue: 233715000000,
    costOfRevenue: 140089000000,
    grossProfit: 93626000000,
    grossProfitRatio: 0.4005990202,
    researchAndDevelopmentExpenses: 8067000000,
    generalAndAdministrativeExpenses: 0,
    sellingAndMarketingExpenses: 0,
    sellingGeneralAndAdministrativeExpenses: 14329000000,
    otherExpenses: 1285000000,
    operatingExpenses: 22396000000,
    costAndExpenses: 162485000000,
    interestIncome: 2921000000,
    interestExpense: 733000000,
    depreciationAndAmortization: 11257000000,
    ebitda: 82487000000,
    ebitdaratio: 0.3529384079,
    operatingIncome: 71230000000,
    operatingIncomeRatio: 0.3047729072,
    totalOtherIncomeExpensesNet: 1285000000,
    incomeBeforeTax: 72515000000,
    incomeBeforeTaxRatio: 0.3102710566,
    incomeTaxExpense: 19121000000,
    netIncome: 53394000000,
    netIncomeRatio: 0.228457737,
    eps: 2.32,
    epsdiluted: 2.31,
    weightedAverageShsOut: 23013684000,
    weightedAverageShsOutDil: 23172276000,
    link: "https://www.sec.gov/Archives/edgar/data/320193/000119312515356351/0001193125-15-356351-index.htm",
    finalLink:
      "https://www.sec.gov/Archives/edgar/data/320193/000119312515356351/d17062d10k.htm",
  },
  {
    date: "2014-09-27",
    symbol: "AAPL",
    reportedCurrency: "USD",
    cik: "0000320193",
    fillingDate: "2014-10-27",
    acceptedDate: "2014-10-27 17:11:55",
    calendarYear: "2014",
    period: "FY",
    revenue: 182795000000,
    costOfRevenue: 112258000000,
    grossProfit: 70537000000,
    grossProfitRatio: 0.3858803578,
    researchAndDevelopmentExpenses: 6041000000,
    generalAndAdministrativeExpenses: 0,
    sellingAndMarketingExpenses: 0,
    sellingGeneralAndAdministrativeExpenses: 11993000000,
    otherExpenses: -431000000,
    operatingExpenses: 18034000000,
    costAndExpenses: 130292000000,
    interestIncome: 1795000000,
    interestExpense: 384000000,
    depreciationAndAmortization: 7946000000,
    ebitda: 61813000000,
    ebitdaratio: 0.3381547635,
    operatingIncome: 52503000000,
    operatingIncomeRatio: 0.2872233923,
    totalOtherIncomeExpensesNet: 980000000,
    incomeBeforeTax: 53483000000,
    incomeBeforeTaxRatio: 0.2925845893,
    incomeTaxExpense: 13973000000,
    netIncome: 39510000000,
    netIncomeRatio: 0.2161437676,
    eps: 1.62,
    epsdiluted: 1.61,
    weightedAverageShsOut: 24342288000,
    weightedAverageShsOutDil: 24490652000,
    link: "https://www.sec.gov/Archives/edgar/data/320193/000119312514383437/0001193125-14-383437-index.htm",
    finalLink:
      "https://www.sec.gov/Archives/edgar/data/320193/000119312514383437/d783162d10k.htm",
  },
  {
    date: "2013-09-28",
    symbol: "AAPL",
    reportedCurrency: "USD",
    cik: "0000320193",
    fillingDate: "2013-10-30",
    acceptedDate: "2013-10-29 20:38:28",
    calendarYear: "2013",
    period: "FY",
    revenue: 170910000000,
    costOfRevenue: 106606000000,
    grossProfit: 64304000000,
    grossProfitRatio: 0.3762448072,
    researchAndDevelopmentExpenses: 4475000000,
    generalAndAdministrativeExpenses: 0,
    sellingAndMarketingExpenses: 0,
    sellingGeneralAndAdministrativeExpenses: 10830000000,
    otherExpenses: 1156000000,
    operatingExpenses: 15305000000,
    costAndExpenses: 121911000000,
    interestIncome: 1616000000,
    interestExpense: 136000000,
    depreciationAndAmortization: 6757000000,
    ebitda: 55756000000,
    ebitdaratio: 0.3262301796,
    operatingIncome: 50479000000,
    operatingIncomeRatio: 0.29535428,
    totalOtherIncomeExpensesNet: 1156000000,
    incomeBeforeTax: 50155000000,
    incomeBeforeTaxRatio: 0.2934585454,
    incomeTaxExpense: 13118000000,
    netIncome: 37037000000,
    netIncomeRatio: 0.2167046984,
    eps: 1.43,
    epsdiluted: 1.42,
    weightedAverageShsOut: 25909268000,
    weightedAverageShsOutDil: 26086536000,
    link: "https://www.sec.gov/Archives/edgar/data/320193/000119312513416534/0001193125-13-416534-index.htm",
    finalLink:
      "https://www.sec.gov/Archives/edgar/data/320193/000119312513416534/d590790d10k.htm",
  },
  {
    date: "2012-09-29",
    symbol: "AAPL",
    reportedCurrency: "USD",
    cik: "0000320193",
    fillingDate: "2012-10-31",
    acceptedDate: "2012-10-31 17:07:19",
    calendarYear: "2012",
    period: "FY",
    revenue: 156508000000,
    costOfRevenue: 87846000000,
    grossProfit: 68662000000,
    grossProfitRatio: 0.4387123981,
    researchAndDevelopmentExpenses: 3381000000,
    generalAndAdministrativeExpenses: 0,
    sellingAndMarketingExpenses: 0,
    sellingGeneralAndAdministrativeExpenses: 10040000000,
    otherExpenses: 522000000,
    operatingExpenses: 13421000000,
    costAndExpenses: 101267000000,
    interestIncome: 1088000000,
    interestExpense: -522000000,
    depreciationAndAmortization: 3277000000,
    ebitda: 58518000000,
    ebitdaratio: 0.3738978199,
    operatingIncome: 55241000000,
    operatingIncomeRatio: 0.3529595931,
    totalOtherIncomeExpensesNet: 522000000,
    incomeBeforeTax: 55763000000,
    incomeBeforeTaxRatio: 0.3562948859,
    incomeTaxExpense: 14030000000,
    netIncome: 41733000000,
    netIncomeRatio: 0.266650906,
    eps: 1.59,
    epsdiluted: 1.58,
    weightedAverageShsOut: 26174904000,
    weightedAverageShsOutDil: 26469940000,
    link: "https://www.sec.gov/Archives/edgar/data/320193/000119312512444068/0001193125-12-444068-index.htm",
    finalLink:
      "https://www.sec.gov/Archives/edgar/data/320193/000119312512444068/d411355d10k.htm",
  },
  {
    date: "2011-09-24",
    symbol: "AAPL",
    reportedCurrency: "USD",
    cik: "0000320193",
    fillingDate: "2011-10-26",
    acceptedDate: "2011-10-26 16:35:25",
    calendarYear: "2011",
    period: "FY",
    revenue: 108249000000,
    costOfRevenue: 64431000000,
    grossProfit: 43818000000,
    grossProfitRatio: 0.4047889588,
    researchAndDevelopmentExpenses: 2429000000,
    generalAndAdministrativeExpenses: 0,
    sellingAndMarketingExpenses: 0,
    sellingGeneralAndAdministrativeExpenses: 7599000000,
    otherExpenses: 415000000,
    operatingExpenses: 10028000000,
    costAndExpenses: 74459000000,
    interestIncome: 519000000,
    interestExpense: -415000000,
    depreciationAndAmortization: 1814000000,
    ebitda: 35604000000,
    ebitdaratio: 0.3289083502,
    operatingIncome: 33790000000,
    operatingIncomeRatio: 0.3121506896,
    totalOtherIncomeExpensesNet: 415000000,
    incomeBeforeTax: 34205000000,
    incomeBeforeTaxRatio: 0.3159844433,
    incomeTaxExpense: 8283000000,
    netIncome: 25922000000,
    netIncomeRatio: 0.2394664154,
    eps: 1,
    epsdiluted: 0.99,
    weightedAverageShsOut: 25879224000,
    weightedAverageShsOutDil: 26226060000,
    link: "https://www.sec.gov/Archives/edgar/data/320193/000119312511282113/0001193125-11-282113-index.htm",
    finalLink:
      "https://www.sec.gov/Archives/edgar/data/320193/000119312511282113/d220209d10k.htm",
  },
  {
    date: "2010-09-25",
    symbol: "AAPL",
    reportedCurrency: "USD",
    cik: "0000320193",
    fillingDate: "2010-10-27",
    acceptedDate: "2010-10-27 16:36:21",
    calendarYear: "2010",
    period: "FY",
    revenue: 65225000000,
    costOfRevenue: 39541000000,
    grossProfit: 25684000000,
    grossProfitRatio: 0.3937753929,
    researchAndDevelopmentExpenses: 1782000000,
    generalAndAdministrativeExpenses: 0,
    sellingAndMarketingExpenses: 0,
    sellingGeneralAndAdministrativeExpenses: 5517000000,
    otherExpenses: 155000000,
    operatingExpenses: 7299000000,
    costAndExpenses: 46840000000,
    interestIncome: 311000000,
    interestExpense: -155000000,
    depreciationAndAmortization: 1027000000,
    ebitda: 19412000000,
    ebitdaratio: 0.2976159448,
    operatingIncome: 18385000000,
    operatingIncomeRatio: 0.2818704484,
    totalOtherIncomeExpensesNet: 155000000,
    incomeBeforeTax: 18540000000,
    incomeBeforeTaxRatio: 0.2842468379,
    incomeTaxExpense: 4527000000,
    netIncome: 14013000000,
    netIncomeRatio: 0.2148409352,
    eps: 0.55,
    epsdiluted: 0.54,
    weightedAverageShsOut: 25464908000,
    weightedAverageShsOutDil: 25891936000,
    link: "https://www.sec.gov/Archives/edgar/data/320193/000119312510238044/0001193125-10-238044-index.htm",
    finalLink:
      "https://www.sec.gov/Archives/edgar/data/320193/000119312510238044/d10k.htm",
  },
  {
    date: "2009-09-26",
    symbol: "AAPL",
    reportedCurrency: "USD",
    cik: "0000320193",
    fillingDate: "2009-10-27",
    acceptedDate: "2009-10-27 16:18:29",
    calendarYear: "2009",
    period: "FY",
    revenue: 36537000000,
    costOfRevenue: 23397000000,
    grossProfit: 13140000000,
    grossProfitRatio: 0.359635438,
    researchAndDevelopmentExpenses: 1333000000,
    generalAndAdministrativeExpenses: 0,
    sellingAndMarketingExpenses: 0,
    sellingGeneralAndAdministrativeExpenses: 4149000000,
    otherExpenses: 326000000,
    operatingExpenses: 5482000000,
    costAndExpenses: 28879000000,
    interestIncome: 407000000,
    interestExpense: -326000000,
    depreciationAndAmortization: 703000000,
    ebitda: 8361000000,
    ebitdaratio: 0.2288365219,
    operatingIncome: 7658000000,
    operatingIncomeRatio: 0.2095957523,
    totalOtherIncomeExpensesNet: 326000000,
    incomeBeforeTax: 7984000000,
    incomeBeforeTaxRatio: 0.2185182144,
    incomeTaxExpense: 2280000000,
    netIncome: 5704000000,
    netIncomeRatio: 0.1561157183,
    eps: 0.23,
    epsdiluted: 0.22,
    weightedAverageShsOut: 25004448000,
    weightedAverageShsOutDil: 25396140000,
    link: "https://www.sec.gov/Archives/edgar/data/320193/000119312509214859/0001193125-09-214859-index.htm",
    finalLink:
      "https://www.sec.gov/Archives/edgar/data/320193/000119312509214859/d10k.htm",
  },
  {
    date: "2008-09-27",
    symbol: "AAPL",
    reportedCurrency: "USD",
    cik: "0000320193",
    fillingDate: "2008-11-05",
    acceptedDate: "2008-11-05 06:16:23",
    calendarYear: "2008",
    period: "FY",
    revenue: 32479000000,
    costOfRevenue: 21334000000,
    grossProfit: 11145000000,
    grossProfitRatio: 0.3431448013,
    researchAndDevelopmentExpenses: 1109000000,
    generalAndAdministrativeExpenses: 0,
    sellingAndMarketingExpenses: 0,
    sellingGeneralAndAdministrativeExpenses: 3761000000,
    otherExpenses: 620000000,
    operatingExpenses: 4870000000,
    costAndExpenses: 26204000000,
    interestIncome: 653000000,
    interestExpense: -620000000,
    depreciationAndAmortization: 473000000,
    ebitda: 6748000000,
    ebitdaratio: 0.2077650174,
    operatingIncome: 6275000000,
    operatingIncomeRatio: 0.1932017611,
    totalOtherIncomeExpensesNet: 620000000,
    incomeBeforeTax: 6895000000,
    incomeBeforeTaxRatio: 0.2122910188,
    incomeTaxExpense: 2061000000,
    netIncome: 4834000000,
    netIncomeRatio: 0.1488346316,
    eps: 0.2,
    epsdiluted: 0.19,
    weightedAverageShsOut: 24684576000,
    weightedAverageShsOutDil: 25259892000,
    link: "https://www.sec.gov/Archives/edgar/data/320193/000119312508224958/0001193125-08-224958-index.htm",
    finalLink:
      "https://www.sec.gov/Archives/edgar/data/320193/000119312508224958/d10k.htm",
  },
  {
    date: "2007-09-29",
    symbol: "AAPL",
    reportedCurrency: "USD",
    cik: "0000320193",
    fillingDate: "2007-11-15",
    acceptedDate: "2007-11-15 16:49:37",
    calendarYear: "2007",
    period: "FY",
    revenue: 24006000000,
    costOfRevenue: 15852000000,
    grossProfit: 8154000000,
    grossProfitRatio: 0.3396650837,
    researchAndDevelopmentExpenses: 782000000,
    generalAndAdministrativeExpenses: 0,
    sellingAndMarketingExpenses: 0,
    sellingGeneralAndAdministrativeExpenses: 2963000000,
    otherExpenses: -48000000,
    operatingExpenses: 3745000000,
    costAndExpenses: 19597000000,
    interestIncome: 647000000,
    interestExpense: -599000000,
    depreciationAndAmortization: 317000000,
    ebitda: 4726000000,
    ebitdaratio: 0.1968674498,
    operatingIncome: 4409000000,
    operatingIncomeRatio: 0.1836624177,
    totalOtherIncomeExpensesNet: 599000000,
    incomeBeforeTax: 5008000000,
    incomeBeforeTaxRatio: 0.208614513,
    incomeTaxExpense: 1512000000,
    netIncome: 3496000000,
    netIncomeRatio: 0.1456302591,
    eps: 0.14,
    epsdiluted: 0.14,
    weightedAverageShsOut: 24208660000,
    weightedAverageShsOutDil: 24900176000,
    link: "https://www.sec.gov/Archives/edgar/data/320193/000104746907009340/0001047469-07-009340-index.htm",
    finalLink:
      "https://www.sec.gov/Archives/edgar/data/320193/000104746907009340/a2181030z10-k.htm",
  },
  {
    date: "2006-09-30",
    symbol: "AAPL",
    reportedCurrency: "USD",
    cik: "0000320193",
    fillingDate: "2006-12-29",
    acceptedDate: "2006-12-29 06:05:58",
    calendarYear: "2006",
    period: "FY",
    revenue: 19315000000,
    costOfRevenue: 13717000000,
    grossProfit: 5598000000,
    grossProfitRatio: 0.2898265597,
    researchAndDevelopmentExpenses: 712000000,
    generalAndAdministrativeExpenses: 0,
    sellingAndMarketingExpenses: 0,
    sellingGeneralAndAdministrativeExpenses: 2433000000,
    otherExpenses: -29000000,
    operatingExpenses: 3145000000,
    costAndExpenses: 16862000000,
    interestIncome: 394000000,
    interestExpense: -365000000,
    depreciationAndAmortization: 225000000,
    ebitda: 3043000000,
    ebitdaratio: 0.1575459487,
    operatingIncome: 2453000000,
    operatingIncomeRatio: 0.1269997411,
    totalOtherIncomeExpensesNet: 365000000,
    incomeBeforeTax: 2818000000,
    incomeBeforeTaxRatio: 0.1458969713,
    incomeTaxExpense: 829000000,
    netIncome: 1989000000,
    netIncomeRatio: 0.1029769609,
    eps: 0.0843,
    epsdiluted: 0.0811,
    weightedAverageShsOut: 23633624000,
    weightedAverageShsOutDil: 24570728000,
    link: "https://www.sec.gov/Archives/edgar/data/320193/000110465906084288/0001104659-06-084288-index.htm",
    finalLink:
      "https://www.sec.gov/Archives/edgar/data/320193/000110465906084288/a06-25759_210k.htm",
  },
  {
    date: "2005-09-24",
    symbol: "AAPL",
    reportedCurrency: "USD",
    cik: "0000320193",
    fillingDate: "2005-12-01",
    acceptedDate: "2005-11-30 21:22:48",
    calendarYear: "2005",
    period: "FY",
    revenue: 13931000000,
    costOfRevenue: 9888000000,
    grossProfit: 4043000000,
    grossProfitRatio: 0.2902160649,
    researchAndDevelopmentExpenses: 534000000,
    generalAndAdministrativeExpenses: 0,
    sellingAndMarketingExpenses: 0,
    sellingGeneralAndAdministrativeExpenses: 1859000000,
    otherExpenses: 0,
    operatingExpenses: 2393000000,
    costAndExpenses: 12281000000,
    interestIncome: 0,
    interestExpense: -165000000,
    depreciationAndAmortization: 179000000,
    ebitda: 1829000000,
    ebitdaratio: 0.1312899289,
    operatingIncome: 1650000000,
    operatingIncomeRatio: 0.1184408872,
    totalOtherIncomeExpensesNet: 165000000,
    incomeBeforeTax: 1815000000,
    incomeBeforeTaxRatio: 0.130284976,
    incomeTaxExpense: 480000000,
    netIncome: 1335000000,
    netIncomeRatio: 0.0958294451,
    eps: 0.0586,
    epsdiluted: 0.0554,
    weightedAverageShsOut: 22636292000,
    weightedAverageShsOutDil: 23992584000,
    link: "https://www.sec.gov/Archives/edgar/data/320193/000110465905058421/0001104659-05-058421-index.htm",
    finalLink:
      "https://www.sec.gov/Archives/edgar/data/320193/000110465905058421/a05-20674_110k.htm",
  },
  {
    date: "2004-09-25",
    symbol: "AAPL",
    reportedCurrency: "USD",
    cik: "0000320193",
    fillingDate: "2004-12-03",
    acceptedDate: "2004-12-02 18:05:49",
    calendarYear: "2004",
    period: "FY",
    revenue: 8279000000,
    costOfRevenue: 6020000000,
    grossProfit: 2259000000,
    grossProfitRatio: 0.2728590409,
    researchAndDevelopmentExpenses: 489000000,
    generalAndAdministrativeExpenses: 0,
    sellingAndMarketingExpenses: 0,
    sellingGeneralAndAdministrativeExpenses: 1421000000,
    otherExpenses: -9000000,
    operatingExpenses: 1910000000,
    costAndExpenses: 7930000000,
    interestIncome: 64000000,
    interestExpense: 3000000,
    depreciationAndAmortization: 150000000,
    ebitda: 554000000,
    ebitdaratio: 0.0669162942,
    operatingIncome: 326000000,
    operatingIncomeRatio: 0.0393767363,
    totalOtherIncomeExpensesNet: 57000000,
    incomeBeforeTax: 383000000,
    incomeBeforeTaxRatio: 0.0462616258,
    incomeTaxExpense: 107000000,
    netIncome: 276000000,
    netIncomeRatio: 0.0333373596,
    eps: 0.0129,
    epsdiluted: 0.0121,
    weightedAverageShsOut: 20809040000,
    weightedAverageShsOutDil: 21693728000,
    link: "https://www.sec.gov/Archives/edgar/data/320193/000104746904035975/0001047469-04-035975-index.htm",
    finalLink:
      "https://www.sec.gov/Archives/edgar/data/320193/000104746904035975/a2147337z10-k.htm",
  },
  {
    date: "2003-09-27",
    symbol: "AAPL",
    reportedCurrency: "USD",
    cik: "0000320193",
    fillingDate: "2003-12-19",
    acceptedDate: "2003-12-19 17:25:45",
    calendarYear: "2003",
    period: "FY",
    revenue: 6207000000,
    costOfRevenue: 4499000000,
    grossProfit: 1708000000,
    grossProfitRatio: 0.2751731916,
    researchAndDevelopmentExpenses: 471000000,
    generalAndAdministrativeExpenses: 0,
    sellingAndMarketingExpenses: 0,
    sellingGeneralAndAdministrativeExpenses: 1212000000,
    otherExpenses: -5000000,
    operatingExpenses: 1683000000,
    costAndExpenses: 6182000000,
    interestIncome: 69000000,
    interestExpense: 8000000,
    depreciationAndAmortization: 113000000,
    ebitda: 202000000,
    ebitdaratio: 0.032543902,
    operatingIncome: 89000000,
    operatingIncomeRatio: 0.0143386499,
    totalOtherIncomeExpensesNet: 3000000,
    incomeBeforeTax: 92000000,
    incomeBeforeTaxRatio: 0.0148219752,
    incomeTaxExpense: 24000000,
    netIncome: 69000000,
    netIncomeRatio: 0.0111164814,
    eps: 0.0036,
    epsdiluted: 0.0032,
    weightedAverageShsOut: 20195336000,
    weightedAverageShsOutDil: 20354096000,
    link: "https://www.sec.gov/Archives/edgar/data/320193/000104746903041604/0001047469-03-041604-index.htm",
    finalLink:
      "https://www.sec.gov/Archives/edgar/data/320193/000104746903041604/a2124888z10-k.htm",
  },
  {
    date: "2002-09-28",
    symbol: "AAPL",
    reportedCurrency: "USD",
    cik: "0000320193",
    fillingDate: "2002-12-19",
    acceptedDate: "2002-12-19 17:20:21",
    calendarYear: "2002",
    period: "FY",
    revenue: 5742000000,
    costOfRevenue: 4139000000,
    grossProfit: 1603000000,
    grossProfitRatio: 0.2791710206,
    researchAndDevelopmentExpenses: 447000000,
    generalAndAdministrativeExpenses: 0,
    sellingAndMarketingExpenses: 0,
    sellingGeneralAndAdministrativeExpenses: 1111000000,
    otherExpenses: 5000000,
    operatingExpenses: 1558000000,
    costAndExpenses: 5697000000,
    interestIncome: 118000000,
    interestExpense: 11000000,
    depreciationAndAmortization: 118000000,
    ebitda: 286000000,
    ebitdaratio: 0.0498084291,
    operatingIncome: 168000000,
    operatingIncomeRatio: 0.0292580982,
    totalOtherIncomeExpensesNet: -81000000,
    incomeBeforeTax: 87000000,
    incomeBeforeTaxRatio: 0.0151515152,
    incomeTaxExpense: 22000000,
    netIncome: 65000000,
    netIncomeRatio: 0.0113200975,
    eps: 0.0032,
    epsdiluted: 0.0032,
    weightedAverageShsOut: 19881232000,
    weightedAverageShsOutDil: 20259960000,
    link: "https://www.sec.gov/Archives/edgar/data/320193/000104746902007674/0001047469-02-007674-index.htm",
    finalLink:
      "https://www.sec.gov/Archives/edgar/data/320193/000104746902007674/a2096490z10-k.htm",
  },
  {
    date: "2001-09-29",
    symbol: "AAPL",
    reportedCurrency: "USD",
    cik: "0000320193",
    fillingDate: "2001-12-21",
    acceptedDate: "2001-12-21 00:00:00",
    calendarYear: "2001",
    period: "FY",
    revenue: 5363000000,
    costOfRevenue: 4128000000,
    grossProfit: 1235000000,
    grossProfitRatio: 0.2302815588,
    researchAndDevelopmentExpenses: 441000000,
    generalAndAdministrativeExpenses: 0,
    sellingAndMarketingExpenses: 0,
    sellingGeneralAndAdministrativeExpenses: 1138000000,
    otherExpenses: 8000000,
    operatingExpenses: 1579000000,
    costAndExpenses: 5707000000,
    interestIncome: 218000000,
    interestExpense: 16000000,
    depreciationAndAmortization: 102000000,
    ebitda: -16000000,
    ebitdaratio: -0.0029834048,
    operatingIncome: -344000000,
    operatingIncomeRatio: -0.0641432034,
    totalOtherIncomeExpensesNet: 292000000,
    incomeBeforeTax: -52000000,
    incomeBeforeTaxRatio: -0.0096960656,
    incomeTaxExpense: -15000000,
    netIncome: -37000000,
    netIncomeRatio: -0.0068991236,
    eps: -0.0019,
    epsdiluted: -0.0019,
    weightedAverageShsOut: 19354328000,
    weightedAverageShsOutDil: 19354328000,
    link: "https://www.sec.gov/Archives/edgar/data/320193/000091205701544436/0000912057-01-544436-index.htm",
    finalLink:
      "https://www.sec.gov/Archives/edgar/data/320193/000091205701544436/a2066171z10-k405.htm",
  },
  {
    date: "2000-09-30",
    symbol: "AAPL",
    reportedCurrency: "USD",
    cik: "0000320193",
    fillingDate: "2000-12-14",
    acceptedDate: "2000-12-14 00:00:00",
    calendarYear: "2000",
    period: "FY",
    revenue: 7983000000,
    costOfRevenue: 5817000000,
    grossProfit: 2166000000,
    grossProfitRatio: 0.271326569,
    researchAndDevelopmentExpenses: 380000000,
    generalAndAdministrativeExpenses: 0,
    sellingAndMarketingExpenses: 0,
    sellingGeneralAndAdministrativeExpenses: 1166000000,
    otherExpenses: 6000000,
    operatingExpenses: 1546000000,
    costAndExpenses: 7363000000,
    interestIncome: 210000000,
    interestExpense: 21000000,
    depreciationAndAmortization: 84000000,
    ebitda: 920000000,
    ebitdaratio: 0.1152448954,
    operatingIncome: 836000000,
    operatingIncomeRatio: 0.1047225354,
    totalOtherIncomeExpensesNet: 256000000,
    incomeBeforeTax: 1092000000,
    incomeBeforeTaxRatio: 0.1367906802,
    incomeTaxExpense: 306000000,
    netIncome: 786000000,
    netIncomeRatio: 0.0984592259,
    eps: 0.0432,
    epsdiluted: 0.0389,
    weightedAverageShsOut: 18175808000,
    weightedAverageShsOutDil: 20178144000,
    link: "https://www.sec.gov/Archives/edgar/data/320193/000091205700053623/0000912057-00-053623-index.htm",
    finalLink:
      "https://www.sec.gov/Archives/edgar/data/320193/000091205700053623/a2032880z10-k.txt",
  },
  {
    date: "1999-09-25",
    symbol: "AAPL",
    reportedCurrency: "USD",
    cik: "0000320193",
    fillingDate: "1999-12-22",
    acceptedDate: "1999-12-22 00:00:00",
    calendarYear: "1999",
    period: "FY",
    revenue: 6134000000,
    costOfRevenue: 4438000000,
    grossProfit: 1696000000,
    grossProfitRatio: 0.2764916857,
    researchAndDevelopmentExpenses: 314000000,
    generalAndAdministrativeExpenses: 0,
    sellingAndMarketingExpenses: 0,
    sellingGeneralAndAdministrativeExpenses: 996000000,
    otherExpenses: -2000000,
    operatingExpenses: 1310000000,
    costAndExpenses: 5748000000,
    interestIncome: 144000000,
    interestExpense: 47000000,
    depreciationAndAmortization: 85000000,
    ebitda: 613000000,
    ebitdaratio: 0.0999347897,
    operatingIncome: 528000000,
    operatingIncomeRatio: 0.0860776003,
    totalOtherIncomeExpensesNet: 148000000,
    incomeBeforeTax: 676000000,
    incomeBeforeTaxRatio: 0.1102054125,
    incomeTaxExpense: 75000000,
    netIncome: 601000000,
    netIncomeRatio: 0.0979784806,
    eps: 0.0375,
    epsdiluted: 0.0323,
    weightedAverageShsOut: 16033584000,
    weightedAverageShsOutDil: 19506368000,
    link: "https://www.sec.gov/Archives/edgar/data/320193/000091205799010244/0000912057-99-010244-index.htm",
    finalLink:
      "https://www.sec.gov/Archives/edgar/data/320193/000091205799010244/0000912057-99-010244.txt",
  },
  {
    date: "1998-09-25",
    symbol: "AAPL",
    reportedCurrency: "USD",
    cik: "0000320193",
    fillingDate: "1998-12-23",
    acceptedDate: "1998-12-23 00:00:00",
    calendarYear: "1998",
    period: "FY",
    revenue: 5941000000,
    costOfRevenue: 4462000000,
    grossProfit: 1479000000,
    grossProfitRatio: 0.2489479886,
    researchAndDevelopmentExpenses: 310000000,
    generalAndAdministrativeExpenses: 0,
    sellingAndMarketingExpenses: 0,
    sellingGeneralAndAdministrativeExpenses: 908000000,
    otherExpenses: -8000000,
    operatingExpenses: 1218000000,
    costAndExpenses: 5680000000,
    interestIncome: 100000000,
    interestExpense: 62000000,
    depreciationAndAmortization: 111000000,
    ebitda: 504000000,
    ebitdaratio: 0.084834203,
    operatingIncome: 299000000,
    operatingIncomeRatio: 0.0503282276,
    totalOtherIncomeExpensesNet: -64000000,
    incomeBeforeTax: 329000000,
    incomeBeforeTaxRatio: 0.0553778825,
    incomeTaxExpense: 20000000,
    netIncome: 309000000,
    netIncomeRatio: 0.0520114459,
    eps: 0.0209,
    epsdiluted: 0.0188,
    weightedAverageShsOut: 14781088000,
    weightedAverageShsOutDil: 18806704000,
    link: "https://www.sec.gov/Archives/edgar/data/320193/000104746998044981/0001047469-98-044981-index.htm",
    finalLink:
      "https://www.sec.gov/Archives/edgar/data/320193/0001047469-98-044981.txt",
  },
  {
    date: "1997-09-26",
    symbol: "AAPL",
    reportedCurrency: "USD",
    cik: "0000320193",
    fillingDate: "1997-12-05",
    acceptedDate: "1997-12-05 00:00:00",
    calendarYear: "1997",
    period: "FY",
    revenue: 7081000000,
    costOfRevenue: 5713000000,
    grossProfit: 1368000000,
    grossProfitRatio: 0.1931930518,
    researchAndDevelopmentExpenses: 860000000,
    generalAndAdministrativeExpenses: 0,
    sellingAndMarketingExpenses: 0,
    sellingGeneralAndAdministrativeExpenses: 1286000000,
    otherExpenses: 3000000,
    operatingExpenses: 2146000000,
    costAndExpenses: 7859000000,
    interestIncome: 82000000,
    interestExpense: 71000000,
    depreciationAndAmortization: 118000000,
    ebitda: -575000000,
    ebitdaratio: -0.0812032199,
    operatingIncome: -778000000,
    operatingIncomeRatio: -0.1098714871,
    totalOtherIncomeExpensesNet: -267000000,
    incomeBeforeTax: -1045000000,
    incomeBeforeTaxRatio: -0.1475780257,
    incomeTaxExpense: 281000000,
    netIncome: -1326000000,
    netIncomeRatio: -0.1872616862,
    eps: -0.0939,
    epsdiluted: -0.0939,
    weightedAverageShsOut: 14118944000,
    weightedAverageShsOutDil: 14118944000,
    link: "https://www.sec.gov/Archives/edgar/data/320193/000104746997006960/0001047469-97-006960-index.htm",
    finalLink:
      "https://www.sec.gov/Archives/edgar/data/320193/0001047469-97-006960.txt",
  },
  {
    date: "1996-09-27",
    symbol: "AAPL",
    reportedCurrency: "USD",
    cik: "0000320193",
    fillingDate: "1996-12-19",
    acceptedDate: "1996-12-19 00:00:00",
    calendarYear: "1996",
    period: "FY",
    revenue: 9833000000,
    costOfRevenue: 8865000000,
    grossProfit: 968000000,
    grossProfitRatio: 0.0984440151,
    researchAndDevelopmentExpenses: 604000000,
    generalAndAdministrativeExpenses: 0,
    sellingAndMarketingExpenses: 0,
    sellingGeneralAndAdministrativeExpenses: 1568000000,
    otherExpenses: -3000000,
    operatingExpenses: 2172000000,
    costAndExpenses: 11037000000,
    interestIncome: 60000000,
    interestExpense: 60000000,
    depreciationAndAmortization: 156000000,
    ebitda: -991000000,
    ebitdaratio: -0.1007830774,
    operatingIncome: -1204000000,
    operatingIncomeRatio: -0.1224448286,
    totalOtherIncomeExpensesNet: -91000000,
    incomeBeforeTax: -1295000000,
    incomeBeforeTaxRatio: -0.1316993796,
    incomeTaxExpense: -479000000,
    netIncome: -816000000,
    netIncomeRatio: -0.0829858639,
    eps: -0.0589,
    epsdiluted: -0.0589,
    weightedAverageShsOut: 13858208000,
    weightedAverageShsOutDil: 13858208000,
    link: "https://www.sec.gov/Archives/edgar/data/320193/000032019396000023/0000320193-96-000023-index.htm",
    finalLink:
      "https://www.sec.gov/Archives/edgar/data/320193/0000320193-96-000023.txt",
  },
  {
    date: "1995-09-29",
    symbol: "AAPL",
    reportedCurrency: "USD",
    cik: "0000320193",
    fillingDate: "1995-12-19",
    acceptedDate: "1995-12-19 00:00:00",
    calendarYear: "1995",
    period: "FY",
    revenue: 11062000000,
    costOfRevenue: 8204000000,
    grossProfit: 2858000000,
    grossProfitRatio: 0.2583619599,
    researchAndDevelopmentExpenses: 614000000,
    generalAndAdministrativeExpenses: 0,
    sellingAndMarketingExpenses: 0,
    sellingGeneralAndAdministrativeExpenses: 1583000000,
    otherExpenses: -1000000,
    operatingExpenses: 2197000000,
    costAndExpenses: 10401000000,
    interestIncome: 100000000,
    interestExpense: 48000000,
    depreciationAndAmortization: 127000000,
    ebitda: 887000000,
    ebitdaratio: 0.0801844151,
    operatingIncome: 684000000,
    operatingIncomeRatio: 0.0618333032,
    totalOtherIncomeExpensesNet: -10000000,
    incomeBeforeTax: 674000000,
    incomeBeforeTaxRatio: 0.0609293075,
    incomeTaxExpense: 250000000,
    netIncome: 424000000,
    netIncomeRatio: 0.038329416,
    eps: 0.0308,
    epsdiluted: 0.0308,
    weightedAverageShsOut: 13781264000,
    weightedAverageShsOutDil: 13781264000,
    link: "https://www.sec.gov/Archives/edgar/data/320193/000032019395000016/0000320193-95-000016-index.htm",
    finalLink:
      "https://www.sec.gov/Archives/edgar/data/320193/0000320193-95-000016.txt",
  },
  {
    date: "1994-09-30",
    symbol: "AAPL",
    reportedCurrency: "USD",
    cik: "0000320193",
    fillingDate: "1994-12-13",
    acceptedDate: "1994-12-13 00:00:00",
    calendarYear: "1994",
    period: "FY",
    revenue: 9188748000,
    costOfRevenue: 6844915000,
    grossProfit: 2343833000,
    grossProfitRatio: 0.2550764261,
    researchAndDevelopmentExpenses: 564303000,
    generalAndAdministrativeExpenses: 0,
    sellingAndMarketingExpenses: 0,
    sellingGeneralAndAdministrativeExpenses: 1384111000,
    otherExpenses: 8685000,
    operatingExpenses: 1948414000,
    costAndExpenses: 8793329000,
    interestIncome: 43284000,
    interestExpense: 39653000,
    depreciationAndAmortization: 167958000,
    ebitda: 615346000,
    ebitdaratio: 0.0669673387,
    operatingIncome: 522274000,
    operatingIncomeRatio: 0.0568384289,
    totalOtherIncomeExpensesNet: -21988000,
    incomeBeforeTax: 500286000,
    incomeBeforeTaxRatio: 0.0544455023,
    incomeTaxExpense: 190108000,
    netIncome: 310178000,
    netIncomeRatio: 0.0337562854,
    eps: 0.0233,
    epsdiluted: 0.0233,
    weightedAverageShsOut: 13298320000,
    weightedAverageShsOutDil: 13298320000,
    link: "https://www.sec.gov/Archives/edgar/data/320193/000032019394000016/0000320193-94-000016-index.htm",
    finalLink:
      "https://www.sec.gov/Archives/edgar/data/320193/0000320193-94-000016.txt",
  },
  {
    date: "1993-09-30",
    symbol: "AAPL",
    reportedCurrency: "USD",
    cik: "0000320193",
    fillingDate: "1993-09-30",
    acceptedDate: "1993-09-30 00:00:00",
    calendarYear: "1993",
    period: "FY",
    revenue: 7977000000,
    costOfRevenue: 5082700000,
    grossProfit: 2894300000,
    grossProfitRatio: 0.3628306381,
    researchAndDevelopmentExpenses: 0,
    generalAndAdministrativeExpenses: 0,
    sellingAndMarketingExpenses: 0,
    sellingGeneralAndAdministrativeExpenses: 2617800000,
    otherExpenses: 166100000,
    operatingExpenses: 2783900000,
    costAndExpenses: 7866600000,
    interestIncome: 0,
    interestExpense: -58600000,
    depreciationAndAmortization: 166100000,
    ebitda: 247200000,
    ebitdaratio: 0.0309890936,
    operatingIncome: 81100000,
    operatingIncomeRatio: 0.0101667293,
    totalOtherIncomeExpensesNet: 58600000,
    incomeBeforeTax: 139700000,
    incomeBeforeTaxRatio: 0.0175128494,
    incomeTaxExpense: 53100000,
    netIncome: 86600000,
    netIncomeRatio: 0.0108562116,
    eps: 0.0065,
    epsdiluted: 0.0065,
    weightedAverageShsOut: 13342000000,
    weightedAverageShsOutDil: 13424000000,
    link: null,
    finalLink: null,
  },
  {
    date: "1992-09-30",
    symbol: "AAPL",
    reportedCurrency: "USD",
    cik: "0000320193",
    fillingDate: "1992-09-30",
    acceptedDate: "1992-09-30 00:00:00",
    calendarYear: "1992",
    period: "FY",
    revenue: 7086500000,
    costOfRevenue: 3774200000,
    grossProfit: 3312300000,
    grossProfitRatio: 0.4674098638,
    researchAndDevelopmentExpenses: 0,
    generalAndAdministrativeExpenses: 0,
    sellingAndMarketingExpenses: 0,
    sellingGeneralAndAdministrativeExpenses: 2289400000,
    otherExpenses: 217200000,
    operatingExpenses: 2506600000,
    costAndExpenses: 6280800000,
    interestIncome: 0,
    interestExpense: -99400000,
    depreciationAndAmortization: 217200000,
    ebitda: 973300000,
    ebitdaratio: 0.1373456572,
    operatingIncome: 805700000,
    operatingIncomeRatio: 0.113695054,
    totalOtherIncomeExpensesNet: 49800000,
    incomeBeforeTax: 855500000,
    incomeBeforeTaxRatio: 0.1207225005,
    incomeTaxExpense: 325100000,
    netIncome: 530400000,
    netIncomeRatio: 0.0748465392,
    eps: 0.0387,
    epsdiluted: 0.0387,
    weightedAverageShsOut: 13718880000,
    weightedAverageShsOutDil: 13729632000,
    link: null,
    finalLink: null,
  },
  {
    date: "1991-09-30",
    symbol: "AAPL",
    reportedCurrency: "USD",
    cik: "0000320193",
    fillingDate: "1991-09-30",
    acceptedDate: "1991-09-30 00:00:00",
    calendarYear: "1991",
    period: "FY",
    revenue: 6308800000,
    costOfRevenue: 3109700000,
    grossProfit: 3199100000,
    grossProfitRatio: 0.5070853411,
    researchAndDevelopmentExpenses: 0,
    generalAndAdministrativeExpenses: 0,
    sellingAndMarketingExpenses: 0,
    sellingGeneralAndAdministrativeExpenses: 2547400000,
    otherExpenses: 204400000,
    operatingExpenses: 2751800000,
    costAndExpenses: 5861500000,
    interestIncome: 0,
    interestExpense: -104800000,
    depreciationAndAmortization: 204400000,
    ebitda: 599300000,
    ebitdaratio: 0.0949942937,
    operatingIncome: 447300000,
    operatingIncomeRatio: 0.0709009637,
    totalOtherIncomeExpensesNet: 52400000,
    incomeBeforeTax: 499700000,
    incomeBeforeTaxRatio: 0.0792068222,
    incomeTaxExpense: 189900000,
    netIncome: 309800000,
    netIncomeRatio: 0.0491060107,
    eps: 0.023,
    epsdiluted: 0.023,
    weightedAverageShsOut: 13448682171,
    weightedAverageShsOutDil: 13448682171,
    link: null,
    finalLink: null,
  },
  {
    date: "1990-09-30",
    symbol: "AAPL",
    reportedCurrency: "USD",
    cik: "0000320193",
    fillingDate: "1990-09-30",
    acceptedDate: "1990-09-30 00:00:00",
    calendarYear: "1990",
    period: "FY",
    revenue: 5558400000,
    costOfRevenue: 2403500000,
    grossProfit: 3154900000,
    grossProfitRatio: 0.5675913932,
    researchAndDevelopmentExpenses: 0,
    generalAndAdministrativeExpenses: 0,
    sellingAndMarketingExpenses: 0,
    sellingGeneralAndAdministrativeExpenses: 2240200000,
    otherExpenses: 202700000,
    operatingExpenses: 2442900000,
    costAndExpenses: 4846400000,
    interestIncome: 0,
    interestExpense: -133000000,
    depreciationAndAmortization: 202700000,
    ebitda: 848200000,
    ebitdaratio: 0.1525978699,
    operatingIncome: 712000000,
    operatingIncomeRatio: 0.1280944157,
    totalOtherIncomeExpensesNet: 66500000,
    incomeBeforeTax: 778500000,
    incomeBeforeTaxRatio: 0.1400582902,
    incomeTaxExpense: 303600000,
    netIncome: 474900000,
    netIncomeRatio: 0.0854382556,
    eps: 0.0338,
    epsdiluted: 0.0338,
    weightedAverageShsOut: 14071111111,
    weightedAverageShsOutDil: 14071111111,
    link: null,
    finalLink: null,
  },
  {
    date: "1989-09-30",
    symbol: "AAPL",
    reportedCurrency: "USD",
    cik: "0000320193",
    fillingDate: "1989-09-30",
    acceptedDate: "1989-09-30 00:00:00",
    calendarYear: "1989",
    period: "FY",
    revenue: 5284000000,
    costOfRevenue: 2570000000,
    grossProfit: 2714000000,
    grossProfitRatio: 0.5136260409,
    researchAndDevelopmentExpenses: 0,
    generalAndAdministrativeExpenses: 0,
    sellingAndMarketingExpenses: 0,
    sellingGeneralAndAdministrativeExpenses: 1954900000,
    otherExpenses: 124800000,
    operatingExpenses: 2079700000,
    costAndExpenses: 4649700000,
    interestIncome: 0,
    interestExpense: -220000000,
    depreciationAndAmortization: 124800000,
    ebitda: 649100000,
    ebitdaratio: 0.1228425435,
    operatingIncome: 634300000,
    operatingIncomeRatio: 0.1200416351,
    totalOtherIncomeExpensesNet: 110000000,
    incomeBeforeTax: 744300000,
    incomeBeforeTaxRatio: 0.1408591976,
    incomeTaxExpense: 290300000,
    netIncome: 454000000,
    netIncomeRatio: 0.0859197578,
    eps: 0.0316,
    epsdiluted: 0.0316,
    weightedAverageShsOut: 14363841808,
    weightedAverageShsOutDil: 14363841808,
    link: null,
    finalLink: null,
  },
  {
    date: "1988-09-30",
    symbol: "AAPL",
    reportedCurrency: "USD",
    cik: "0000320193",
    fillingDate: "1988-09-30",
    acceptedDate: "1988-09-30 00:00:00",
    calendarYear: "1988",
    period: "FY",
    revenue: 4071400000,
    costOfRevenue: 1913200000,
    grossProfit: 2158200000,
    grossProfitRatio: 0.5300879304,
    researchAndDevelopmentExpenses: 0,
    generalAndAdministrativeExpenses: 0,
    sellingAndMarketingExpenses: 0,
    sellingGeneralAndAdministrativeExpenses: 1460200000,
    otherExpenses: 77700000,
    operatingExpenses: 1537900000,
    costAndExpenses: 3451100000,
    interestIncome: 0,
    interestExpense: -71700000,
    depreciationAndAmortization: 77700000,
    ebitda: 662200000,
    ebitdaratio: 0.1626467554,
    operatingIncome: 620300000,
    operatingIncomeRatio: 0.1523554551,
    totalOtherIncomeExpensesNet: 35900000,
    incomeBeforeTax: 656200000,
    incomeBeforeTaxRatio: 0.1611730609,
    incomeTaxExpense: 255900000,
    netIncome: 400300000,
    netIncomeRatio: 0.0983199882,
    eps: 0.0275,
    epsdiluted: 0.0275,
    weightedAverageShsOut: 14556363636,
    weightedAverageShsOutDil: 14556363636,
    link: null,
    finalLink: null,
  },
  {
    date: "1987-09-30",
    symbol: "AAPL",
    reportedCurrency: "USD",
    cik: "0000320193",
    fillingDate: "1987-09-30",
    acceptedDate: "1987-09-30 00:00:00",
    calendarYear: "1987",
    period: "FY",
    revenue: 2661100000,
    costOfRevenue: 1225700000,
    grossProfit: 1435400000,
    grossProfitRatio: 0.5394009996,
    researchAndDevelopmentExpenses: 0,
    generalAndAdministrativeExpenses: 0,
    sellingAndMarketingExpenses: 0,
    sellingGeneralAndAdministrativeExpenses: 993400000,
    otherExpenses: 70500000,
    operatingExpenses: 1063900000,
    costAndExpenses: 2289600000,
    interestIncome: 0,
    interestExpense: -77800000,
    depreciationAndAmortization: 70500000,
    ebitda: 403100000,
    ebitdaratio: 0.1514787118,
    operatingIncome: 371500000,
    operatingIncomeRatio: 0.1396039232,
    totalOtherIncomeExpensesNet: 38900000,
    incomeBeforeTax: 410400000,
    incomeBeforeTaxRatio: 0.1542219383,
    incomeTaxExpense: 192900000,
    netIncome: 217500000,
    netIncomeRatio: 0.0817331179,
    eps: 0.0148,
    epsdiluted: 0.0148,
    weightedAverageShsOut: 14674698795,
    weightedAverageShsOutDil: 14674698795,
    link: null,
    finalLink: null,
  },
  {
    date: "1986-09-30",
    symbol: "AAPL",
    reportedCurrency: "USD",
    cik: "0000320193",
    fillingDate: "1986-09-30",
    acceptedDate: "1986-09-30 00:00:00",
    calendarYear: "1986",
    period: "FY",
    revenue: 1901900000,
    costOfRevenue: 840000000,
    grossProfit: 1061900000,
    grossProfitRatio: 0.5583364004,
    researchAndDevelopmentExpenses: 0,
    generalAndAdministrativeExpenses: 0,
    sellingAndMarketingExpenses: 0,
    sellingGeneralAndAdministrativeExpenses: 737300000,
    otherExpenses: 51100000,
    operatingExpenses: 788400000,
    costAndExpenses: 1628400000,
    interestIncome: 0,
    interestExpense: -72500000,
    depreciationAndAmortization: 51100000,
    ebitda: 288400000,
    ebitdaratio: 0.1516378358,
    operatingIncome: 273500000,
    operatingIncomeRatio: 0.1438035649,
    totalOtherIncomeExpensesNet: 36300000,
    incomeBeforeTax: 309800000,
    incomeBeforeTaxRatio: 0.1628897418,
    incomeTaxExpense: 155800000,
    netIncome: 154000000,
    netIncomeRatio: 0.0809716599,
    eps: 0.0107,
    epsdiluted: 0.0107,
    weightedAverageShsOut: 14373333333,
    weightedAverageShsOutDil: 14373333333,
    link: null,
    finalLink: null,
  },
  {
    date: "1985-09-30",
    symbol: "AAPL",
    reportedCurrency: "USD",
    cik: "0000320193",
    fillingDate: "1985-09-30",
    acceptedDate: "1985-09-30 00:00:00",
    calendarYear: "1985",
    period: "FY",
    revenue: 1918300000,
    costOfRevenue: 1076000000,
    grossProfit: 842300000,
    grossProfitRatio: 0.4390866913,
    researchAndDevelopmentExpenses: 0,
    generalAndAdministrativeExpenses: 0,
    sellingAndMarketingExpenses: 0,
    sellingGeneralAndAdministrativeExpenses: 653200000,
    otherExpenses: 41800000,
    operatingExpenses: 695000000,
    costAndExpenses: 1771000000,
    interestIncome: 0,
    interestExpense: 54500000,
    depreciationAndAmortization: 41800000,
    ebitda: 216300000,
    ebitdaratio: 0.1127560861,
    operatingIncome: 147300000,
    operatingIncomeRatio: 0.0767867383,
    totalOtherIncomeExpensesNet: -27300000,
    incomeBeforeTax: 120000000,
    incomeBeforeTaxRatio: 0.0625553876,
    incomeTaxExpense: 58800000,
    netIncome: 61200000,
    netIncomeRatio: 0.0319032477,
    eps: 0.0045,
    epsdiluted: 0.0045,
    weightedAverageShsOut: 13708800000,
    weightedAverageShsOutDil: 13708800000,
    link: null,
    finalLink: null,
  },
];
